import React from "react";
import { Button, Col } from "react-bootstrap";
import { Settings } from "../../settings";
import { Link } from "react-router-dom";

const LessonMenu = ({ update, loading }) => {
    return (
        <>
            <Col>
                <Button
                    as={Link} to={Settings.links.lessons}
                    className="mt-2 me-2">Назад</Button>
                <Button onClick={update}
                    className="mt-2"
                    disabled={loading}>Обновить</Button>
            </Col>
        </>
    )
}

export default LessonMenu;