import React, { useEffect, useState } from 'react';
import LearnCard from '../learn-card/learn-card';
import { shuffle } from "../../functions";

const getShuffledWords = (words) => {
    return shuffle(JSON.parse(JSON.stringify(words)));
}

const Learn = ({ words }) => {
    const [current, setCurrent] = useState(0);
    const [learnWords, setLearnWords] = useState(getShuffledWords(words));
    const [prewWords, setPrewWords] = useState(words);

    const nextWord = () => {
        console.log('next word clicked');
        if (current + 1 >= learnWords.length) {
            setCurrent(0);
        } else {
            setCurrent(current + 1);
        }

    }

    useEffect(() => {
        if (words !== prewWords) {
            console.log('use effect learn change');
            setCurrent(0);
            setLearnWords(getShuffledWords(words));
            setPrewWords(words);
        }
    });

    return (
        <>
            <LearnCard
                word={learnWords[current]}
                nextWord={nextWord}
            />
        </>
    )
}

export default Learn;