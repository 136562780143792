import React, { useEffect } from "react";
import CollectionCard from "../collection-card";
import { Row, Spinner } from 'react-bootstrap';
import { collectionsRequested, collectionsResponse, collectionsError } from '../../actions';
import WithLearnService from '../hoc';
import { connect } from 'react-redux';

function Lessons(props) {

    const { currentLanguage, collections, collectionsRequested, collectionsResponse, collectionsError, LearnService } = props;

    useEffect(() => {
        console.log('lessons update props');
        if (!collections && currentLanguage) {
            const abortController = new AbortController();
            collectionsRequested();
            LearnService.getCollections(currentLanguage.prefix, abortController)
                .then(res => {
                    console.log('collections load', res);
                    collectionsResponse(res);

                })
                .catch(err => {
                    console.log('languages load error', err);
                    collectionsError();
                })
            return function cancel() {
                abortController.abort();
            }
        }
    });

    return (
        <>
            <h1>Изучение и повторение слов</h1>
            <Row className="mx-auto">
                {collections === undefined ?
                    <Spinner animation="border" variant="primary" /> :
                    collections.map((collection) => {
                        return (
                            <CollectionCard
                                key={collection.id}
                                collection={collection}
                                prefix={currentLanguage.prefix}
                            />
                        )
                    })
                }
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        currentLanguage: state.currentLanguage,
        collections: state.collections
    }
}

const mapDispatchToProps = {
    collectionsRequested,
    collectionsResponse,
    collectionsError
};

export default WithLearnService()(connect(mapStateToProps, mapDispatchToProps)(Lessons));