const languageSelected = (language) => {
    return {
        type: 'LANGUAGE_SELECTED',
        payload: language
    };
};

const languagesRequested = () => {
    return {
        type: 'LANGUAGES_REQUESTED'
    };
};

const languagesResponse = (languages) => {
    return {
        type: 'LANGUAGES_RESPONSE',
        payload: languages
    };
};

const languagesError = () => {
    return {
        type: 'LANGUAGES_ERROR'
    };
};

const collectionsRequested = () => {
    return {
        type: 'COLLECTIONS_REQUESTED'
    };
};

const collectionsResponse = (collections) => {
    return {
        type: 'COLLECTIONS_RESPONSE',
        payload: collections
    };
};

const collectionsError = () => {
    return {
        type: 'COLLECTIONS_ERROR'
    };
};

export {
    languageSelected,
    languagesRequested,
    languagesResponse,
    languagesError,
    collectionsRequested,
    collectionsResponse,
    collectionsError,
}
