import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navigation from './components/navigation';
import { Container } from 'react-bootstrap';
import { HomePage, LessonsPage, NotFoundPage, TheoryPage, LearnPage, WorkoutPage } from './components/pages';
import { Settings } from './settings';
import './App.css';
import Footer from './components/footer';
import styled from 'styled-components';
import { sendMetrik } from './utils/metriks';

const ColoredDiv = styled.div`
  background-color: ${props => props.back};
`;

const ContentDiv = styled.div`
  padding: 10px;
  margin: 0 auto;
`;

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainDiv = styled.div`
  flex: 1 0;
  background: #A0D0FF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wcRChoZrOg/fQAAAFFJREFUGNNjbN/y6L+4lCwDDPz49oWBg4sHzmcSl5JlePnsMVyAg4uH4ce3LwgFDAwMDPgUMcEEcSliQjYOmyImdDvRFTFhcxiyIiZcrocpAgBTPDB6z7qZjQAAAABJRU5ErkJggg==);
`;
const FooterDiv = styled.div`
  flex: 0 0;
`;

function App() {
  const [prewPath, setPrewPath] = useState(undefined);
  const location = useLocation();
  useEffect(() => {
    if (prewPath === undefined) {
      setPrewPath(location.pathname);
    } else {
      if (location.pathname !== prewPath) {
        console.log('send metrik!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        setPrewPath(location.pathname);
        sendMetrik('hit', window.location.href);
      }
    }
  }, [location]);
  return (
    //       <WrapperDiv>
    //         <ContentDiv1 style={{minHeight: '200px'}}>
    // 1
    //         </ContentDiv1>
    //         <FooterDiv style={{height: '20px'}}>
    // 2
    //         </FooterDiv>
    //       </WrapperDiv>


    <>
      <WrapperDiv>
        <MainDiv>
          <Container fluid="xxl">
            <ColoredDiv back={Settings.colors.background}>
              <Navigation />
              <ContentDiv>
                <Routes>
                  <Route path={Settings.links.home} element={<HomePage />} />
                  <Route path={Settings.links.lessons} element={<LessonsPage />} />
                  <Route path={Settings.links.theory} element={<TheoryPage />} />
                  <Route path={Settings.links.learn + '/:prefix/:id'} element={<LearnPage />} />
                  <Route path={Settings.links.workout + '/:prefix/:id'} element={<WorkoutPage />} />
                  <Route path='*' element={<NotFoundPage />} />
                </Routes>
              </ContentDiv>
            </ColoredDiv>
          </Container>
        </MainDiv>
        <FooterDiv>
          <Footer />
        </FooterDiv>
      </WrapperDiv>
    </>
  );
}

export default App;
