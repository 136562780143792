import { Settings } from "../settings";

const initState = {
    currentLanguage: undefined
}

const reducer = (state = initState, action) => {
    console.log('reducer state', state);
    switch (action.type) {
        case 'LANGUAGE_SELECTED':
            return {
                ...state,
                currentLanguage: action.payload,
                collections: undefined,
            }
        case 'LANGUAGES_REQUESTED':
            return {
                ...state,
                loadingLanguages: true,
                languages: undefined,
                currentLanguage: undefined,
                collections: undefined,
            }
        case 'LANGUAGES_RESPONSE':
            return {
                ...state,
                loadingLanguages: false,
                languages: action.payload,
                currentLanguage: action.payload[0],
                collections: undefined,
            }
        case 'LANGUAGES_ERROR':
            return {
                ...state,
                loadingLanguages: false,
                languages: undefined,
                currentLanguage: undefined,
                collections: undefined,
            }
        case 'COLLECTIONS_REQUESTED':
            return {
                ...state,
                loadingCollections: true,
                collections: undefined,
            }
        case 'COLLECTIONS_RESPONSE':
            return {
                ...state,
                loadingCollections: false,
                collections: action.payload,
            }
        case 'COLLECTIONS_ERROR':
            return {
                ...state,
                loadingCollections: false,
                collections: undefined,
            }
        default:
            return state;
    }
}

export default reducer;