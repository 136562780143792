import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Navbar, Container, Nav, Image, NavDropdown, Spinner } from 'react-bootstrap';
import { Settings } from '../../settings';
import { connect } from 'react-redux';
import { languageSelected, languagesRequested, languagesResponse, languagesError } from '../../actions';
import WithLearnService from '../hoc';

const navMenu = [
    { name: "Главная", link: Settings.links.home },
    { name: "Уроки", link: Settings.links.lessons },
    { name: "Теория", link: Settings.links.theory },
];

const Navigation = (props) => {

    const location = useLocation();
    const { LearnService, currentLanguage, languages, languageSelected, languagesRequested, languagesResponse, languagesError } = props;

    useEffect(() => {
        console.log('nav update props');
        if (!languages) {
            const abortController = new AbortController();
            languagesRequested();
            LearnService.getLanguages(abortController)
                .then(res => {
                    console.log('languages load', res);
                    languagesResponse(res);
                })
                .catch(err => {
                    console.log('languages load error', err);
                    languagesError();
                })
            return function cancel() {
                abortController.abort();
            }
        }
    });

    const selectLanguage = (languageId) => {
        if (languageId !== currentLanguage.id) {
            const lang = languages.find(el => el.id === languageId);
            console.log('select language', languageId, lang);
            languageSelected(lang);
        }
    }

    return (
        <Navbar bg="primary" variant="dark">
            <Container fluid>
                <Nav className="me-auto">
                    <Navbar.Brand as={Link} to={Settings.links.home}>{Settings.appName}</Navbar.Brand>
                    {
                        navMenu.map((element, id) => {
                            let navLinkClasses = "nav-link";
                            const pos = location.pathname.indexOf('/', 1);
                            const str = pos > 0 ? location.pathname.slice(0, pos) : location.pathname;
                            const activeLink = str === element.link ? " active" : "";
                            navLinkClasses = navLinkClasses + activeLink;
                            return (
                                <Nav.Link
                                    as={Link}
                                    key={id}
                                    to={element.link}
                                    className={navLinkClasses}>
                                    {element.name}
                                </Nav.Link>
                            )
                        })
                    }
                    {currentLanguage === undefined ?
                        <Spinner animation="border" variant="light" className="mt-1" /> :
                        <NavDropdown title={<Image src={Settings.urlStatic + currentLanguage.icon} height="20px" />}>
                            {
                                languages.map((language) => (
                                    <NavDropdown.Item key={language.id} onClick={() => selectLanguage(language.id)}>
                                        <Image src={Settings.urlStatic + language.icon} />
                                    </NavDropdown.Item>
                                )
                                )
                            }
                        </NavDropdown>
                    }
                </Nav>
            </Container>
        </Navbar>
    )
}

const mapStateToProps = (state) => {
    return {
        currentLanguage: state.currentLanguage,
        languages: state.languages
    }
}

const mapDispatchToProps = {
    languageSelected,
    languagesRequested,
    languagesResponse,
    languagesError

};

export default WithLearnService()(connect(mapStateToProps, mapDispatchToProps)(Navigation));