import React, { useEffect, useState } from "react";
import { Settings } from "../../settings";
import { shuffle } from "../../functions";
import { Col, Row } from "react-bootstrap";
import WorkoutCard from "../workout-card";

const WorkoutLesson2 = ({ words, lessonPassed, viceVersa }) => {

    const shuffledWords = shuffle(JSON.parse(JSON.stringify(words)), Settings.wordsCountLesson2);
    const [lessonWords1, setLessonWords1] = useState(shuffledWords);
    const [lessonWords2, setLessonWords2] = useState(shuffle(JSON.parse(JSON.stringify(shuffledWords))));
    const [prewWords, setPrewWords] = useState(words);
    let successTimer = undefined;

    const unselectWrong = (words) => {
        words.forEach(word => {
            word.wrong = false;
        });
    }

    const changeWords = (words1, words2, id) => {
        const wordChecked = words1.find(word => word.id === id); // по этому слову кликнули
        if (wordChecked.success === true) {
            // слово уже зелёное
            return;
        }
        if (wordChecked.tried === true) {
            // слово синее, делаем белым
            wordChecked.tried = false;
            return;
        }
        let tempWord1 = words1.find(word => word.tried === true);
        let tempWord2 = words2.find(word => word.tried === true);
        if (tempWord1 === undefined && tempWord2 === undefined) {
            // нет синих делаем синим
            wordChecked.tried = true;
            return;
        }
        if (tempWord1) {
            // синее в первом, меняем синее, убираем красные во втором
            tempWord1.tried = false;
            wordChecked.tried = true;
            unselectWrong(words2);
            return;
        }
        if (tempWord2) {
            // синее во втором, проверяем id
            if (tempWord2.id === id) {
                // id верное - делаем зелёными оба и убираем красные
                tempWord2.tried = false;
                tempWord2.success = true;
                wordChecked.success = true;
                unselectWrong(words1);
            } else {
                // id не верное - делаем красным
                wordChecked.wrong = true;
            }
            return;
        }
    }

    // set1 === true, если click по первому столбцу
    const updateWords = (id, set1) => {
        const words1 = set1 ? JSON.parse(JSON.stringify(lessonWords1)) : JSON.parse(JSON.stringify(lessonWords2));
        const words2 = !set1 ? JSON.parse(JSON.stringify(lessonWords1)) : JSON.parse(JSON.stringify(lessonWords2));
        changeWords(words1, words2, id);
        if (set1) {
            setLessonWords1(words1);
            setLessonWords2(words2);
        } else {
            setLessonWords1(words2);
            setLessonWords2(words1);
        }
    }

    const selectCard1 = (id) => {
        updateWords(id, true);
    }

    const selectCard2 = (id) => {
        updateWords(id, false);
    }

    useEffect(() => {
        if (words !== prewWords) {
            setLessonWords1(shuffledWords);
            setLessonWords2(shuffle(JSON.parse(JSON.stringify(shuffledWords))));
            setPrewWords(words);
        } else {
            // проверка что все слова определены
            if (lessonWords1.find(word => word.success !== true) === undefined) {
                if (lessonWords2.find(word => word.success !== true) === undefined) {
                    successTimer = setTimeout(() => {
                        lessonPassed();
                    }, Settings.successTimeout);
                    return () => {
                        clearTimeout(successTimer);
                    }
                }
            }
        }
    });

    return (
        <>
            <h2>Выберите слово и его перевод</h2>
            <Row>
                <Col>
                    {
                        lessonWords1.map((word) => {
                            return (
                                <WorkoutCard
                                    key={word.id}
                                    word={word}
                                    selectCard={selectCard1}
                                    viceVersa={viceVersa}
                                />
                            )
                        })
                    }
                </Col>
                <Col>
                    {
                        lessonWords2.map((word) => {
                            return (
                                <WorkoutCard
                                    key={word.id}
                                    word={word}
                                    selectCard={selectCard2}
                                    viceVersa={!viceVersa}
                                />
                            )
                        })
                    }
                </Col>
            </Row>

        </>
    )
}

export default WorkoutLesson2;