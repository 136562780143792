import React from "react";

const HomeCarousel = () => {
    return (
        <>
            <hr />
        </>
    )
}

export default HomeCarousel;