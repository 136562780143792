import React from 'react';
import Learn from '../learn';
import Lesson from '../lesson';

function LearnPage() {
    return (
        <Lesson
            title='Изучение'
        >
            <Learn />
        </Lesson>
    )
}

export default LearnPage;