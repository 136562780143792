import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Settings } from '../../settings';

const CollectionCard = ({ collection, prefix }) => {
    const { id, title, image } = collection;
    console.log('src image', image);

    return (
        <Card className="mb-1 me-1 ms-1 mt-1" style={{ width: '26rem' }} border="info">
            <Card.Header className="bg-info"><strong>{title}</strong></Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={8}>
                        {image === undefined ?
                            <div style={{ minHeight: '255px' }}></div> :
                            <Card.Img src={Settings.urlStatic + image} />
                        }
                    </Col>
                    <Col>
                        <Row>
                            <Button as={Link} to={Settings.links.learn + '/' + prefix + '/' + id} className="mb-1">Изучение</Button>
                        </Row>
                        <Row>
                            <Button as={Link} to={Settings.links.workout + '/' + prefix + '/' + id}>Повторение</Button>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CollectionCard;