import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Settings } from "../../settings";

const Footer = () => {
    const year = new Date().getFullYear();
    const url = new URL(Settings.siteUrl).hostname;
    console.log('url', url);
    return (
        <Navbar bg="primary" variant="dark">
            <Container fluid="xxl">
                <Nav className="me-auto">
                    <Navbar.Text>{Settings.appName + ' © ' + year + ' ' + url}</Navbar.Text>
                </Nav>
                <div className="ya-share2" data-curtain data-shape="round" data-services="vkontakte,facebook,odnoklassniki,twitter,moimir"></div>
            </Container>
        </Navbar>
    )
}

export default Footer;