import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

const WorkoutCard = ({ word, foreign, selectCard, viceVersa }) => {

    const [prewWord, setPrewWord] = useState(word);

    const calcTitle = () => {
        if (viceVersa) {
            return foreign ? word.translate : word.value;
        }
        return foreign ? word.value : word.translate;
    }

    const title = calcTitle();

    const getClassName = () => {
        if (word.success) {
            return 'bg-success';
        }
        if (word.tried) {
            return 'bg-info';
        }
        if (word.wrong) {
            return 'bg-danger';
        }
        return "bg-light";
    }

    useEffect(() => {
        if (word !== prewWord) {
            setPrewWord(word);
        }
    });

    return (
        <Card className="mb-1 me-1 ms-1 mt-1" style={{ width: '26rem' }} border="info">
            {foreign ?
                <Card.Header
                    className="bg-info"
                    style={{ fontSize: '36px', textAlign: 'center' }}
                ><strong>{title}</strong></Card.Header> :
                <Card.Header
                    onClick={() => selectCard(word.id)}
                    className={getClassName()}
                    style={{ fontSize: '36px', textAlign: 'center', cursor: 'pointer' }}
                ><strong>{title}</strong></Card.Header>
            }
        </Card>
    )
}

export default WorkoutCard;