import React, { useState, useEffect } from 'react';
import { Settings } from '../../settings';
import WorkoutLesson1 from '../workout-lesson1';
import WorkoutLesson2 from '../workout-lesson2';

const Workout = ({ words }) => {
    const lessonNumber = Math.floor(Math.random() * (Settings.lessonsCount)) + 1;

    const [lessonWords, setLessonWords] = useState(JSON.parse(JSON.stringify(words)));

    const lessonPassed = () => {
        setLessonWords(JSON.parse(JSON.stringify(words)));
    }

    const vv = Math.random() < 0.5;

    // useEffect(() => {
        
    // });

    return (
        <>
            {lessonNumber === 1 &&
                <WorkoutLesson1 words={lessonWords} viceVersa={vv} lessonPassed={lessonPassed} />
            }
            {lessonNumber === 2 &&
                <WorkoutLesson2 words={lessonWords} viceVersa={vv} lessonPassed={lessonPassed} />
            }

        </>
    )
}

export default Workout;