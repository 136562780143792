import React from 'react';
import LearnService from '../../services/learn-service';
import LearnServiceContext from '../learn-service-context';

const WithLearnService = () => (Wrapped) => {
    return (props) => {
        return (
            <LearnServiceContext.Consumer>
                {
                    (LearnService) => {
                        return <Wrapped {...props} LearnService={LearnService}/>
                    }
                }
            </LearnServiceContext.Consumer>
        )
    };
};

export default WithLearnService;