import React, { useState, useEffect } from "react";
import WorkoutCard from "../workout-card";
import { randomItem, shuffle } from "../../functions";
import { Settings } from "../../settings";

const addSuccess = (array, id) => {
    array.find(element => element.id === id).success = true;
    return array
}

const WorkoutLesson1 = ({ words, lessonPassed, viceVersa }) => {

    const shuffledWords = shuffle(JSON.parse(JSON.stringify(words)), Settings.wordsCountLesson1);
    const randomWord = randomItem(shuffledWords);
    const [currentWord, setCurrentWord] = useState(randomWord);
    const [lessonWords, setLessonWords] = useState(shuffledWords);
    const [prewWords, setPrewWords] = useState(words);
    const [success, setSuccess] = useState(false);

    let successTimer = undefined;

    const selectCard = (id) => {
        if (id === currentWord.id) {
            setSuccess(true);
            const correctedWords = JSON.parse(JSON.stringify(lessonWords));
            correctedWords.find(word => word.id === id).success = true;
            setLessonWords(correctedWords);
        } else {
            if (success === false) {
                const correctedWords = JSON.parse(JSON.stringify(lessonWords));
                correctedWords.find(word => word.id === id).wrong = true;
                setLessonWords(correctedWords);
            }
        }
    }

    useEffect(() => {
        if (words !== prewWords) {
            setCurrentWord(randomWord);
            setLessonWords(shuffledWords);
            setPrewWords(words);
            setSuccess(false);
        } else {
            if (success) {
                successTimer = setTimeout(() => {
                    lessonPassed();
                }, Settings.successTimeout);
                return () => {
                    clearTimeout(successTimer);
                }
            }
        }


    });

    return (
        <>
            <h2>Выберите перевод</h2>
            <WorkoutCard
                word={currentWord}
                foreign
                viceVersa={viceVersa}
            />
            <br />
            {
                lessonWords.map((word) => {
                    return (
                        <WorkoutCard
                            key={word.id}
                            word={word}
                            selectCard={selectCard}
                            viceVersa={viceVersa}
                        />
                    )
                })
            }
        </>
    )
}

export default WorkoutLesson1;