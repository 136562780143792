import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Settings } from '../../settings';

const NotFound = () => {
    return (
        <>
            <h1>Страница не найдена!</h1>
            <p>
                Такая страница отсутствует на нашем сервисе. Возможно, вы ошиблись при наборе адреса или перешли по неверной ссылке.
            </p>
            <Button as={Link} to={Settings.links.home}>ВЕРНУТЬСЯ НА ГЛАВНУЮ</Button>
        </>
    )
}

export default NotFound;