import { Settings } from "../settings";

export default class LearnService {
    url = Settings.urlApi;

    getLanguages = async (abortController) => {
        console.log('get languages in learn service');
        const response = await fetch(this.url + 'languages/', {
            signal: abortController.signal
        });
        if (!response.ok) {
            throw new Error('Server Error');
        }
        const result = await response.json();
        return result;
    }

    getCollections = async (prefix, abortController) => {
        console.log('get languages in learn service');
        const response = await fetch(this.url + prefix + '/collections/', {
            signal: abortController.signal
        });
        if (!response.ok) {
            throw new Error('Server Error');
        }
        const result = await response.json();
        return result;
    }

    getWords = async (prefix, collectionId, abortController, wordsCount=Settings.defaultWordsCount) => {
        console.log('get languages in learn service');
        const response = await fetch(`${this.url + prefix}/words/${collectionId}/${wordsCount}`, {
            signal: abortController.signal
        });
        if (!response.ok) {
            throw new Error('Server Error');
        }
        const result = await response.json();
        return result;
    }
}