import React, { useState } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { Settings } from "../../settings";
import styled from 'styled-components';

const ImageBlur = styled(Card.Img)`
  filter: ${props => props.$blur ? "blur(20px)" : "blur(0)"};
`;

// const StrongText = styled.strong`
//     font-size: 36px;
//     min-height: 46px;
//     text-align: center;
// `;

const WordCard = ({ word, nextWord }) => {
    const [learned, setLearned] = useState(false);
    const { value, translate, image } = word;
    const clickNext = () => {
        setLearned(false);
        nextWord();
    }
    console.log('src image', image);
    return (
        <Card className="mb-1 me-1 ms-1 mt-1" style={{ width: '26rem' }} border="info">
            <Card.Header className="bg-info" style={{ fontSize: '36px', textAlign: 'center' }}><strong>{word.value}</strong></Card.Header>
            <Card.Body style={{ minHeight: '255px' }}>
                <div style={{ minHeight: '350px' }}>
                    {image === undefined ?
                        <div style={{ minHeight: '150px' }} /> :
                        <ImageBlur $blur={!learned} src={Settings.urlStatic + image} />
                    }
                    <Card.Title style={{ fontSize: '36px', minHeight: '46px', textAlign: 'center' }}><strong>{learned ? word.translate : ''}</strong></Card.Title>
                </div>
                <Row className="justify-content-end me-1">
                    {learned ?
                        <Button onClick={clickNext} style={{ width: '8rem' }}>Следующее</Button> :
                        <Button onClick={() => setLearned(true)} style={{ width: '8rem' }}>Перевод</Button>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}

export default WordCard;