const serviceUrl = 'https://words24.ru/';

const Settings = {
    appName: 'Words 24',
    urlApi: serviceUrl + 'api/',
    urlStatic: serviceUrl,
    siteUrl: serviceUrl,
    defaultLanguageId: 0,
    defaultWordsCount: 6,
    links: {
        lessons: '/lessons',
        home: '/',
        theory: '/theory',
        workout: '/workout',
        learn: '/learn'
    },
    colors: {
        background: '#A9F3FF'
    },
    successTimeout: 500,
    wordsCountLesson1: 5,
    wordsCountLesson2: 5,
    lessonsCount: 2
}

export {
    Settings,
}
// 87CEFA
// 87CEEB
// '#99FFFF'