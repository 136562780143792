const randomItem = (array) => {
    return array[Math.floor(Math.random() * array.length)];
}

const shuffle = (array, maxCount = undefined) => {
    const newArray= array.sort(() => Math.random() - 0.5);
    if (maxCount === undefined) {
        return newArray;
    }
    return newArray.length <= maxCount ? newArray : newArray.slice(0, maxCount);
}

export {
    randomItem,
    shuffle
}
