import React from 'react';

const Theory = () => {
    return (
        <>
            <h1>Теория</h1>
        </>
    )
}

export default Theory;