import React from "react";

const HomeSet = () => {
    return (
        <>
            <hr />
        </>
    )
}

export default HomeSet;