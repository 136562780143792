import React from "react";
import { Settings } from "../../settings";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HomeSet from "../home-set";
import HomeCarousel from "../home-carousel";
function Home() {
    return (
        <>
            <h1>Добро пожаловать на {Settings.appName}!</h1>
            <p>Изучайте иностранные языки вместе с бесплатным сервисом {Settings.appName}.</p>
            <Button as={Link} to={Settings.links.lessons}>НАЧАТЬ</Button>
            <h2>Выучите иностранные слова</h2>
            <p>{Settings.appName} - это простой и удобный в использовании сервис для изучения слов иностранных языков. Он поможет вам изучить новые иностранные слова и повторить уже выученные.</p>
            <HomeSet />
            <h2>Особенности обучения</h2>
            <p>
                Наш сервис содержит всё необходимое для того, чтобы вы могли быстро и легко изучать новые иностранные слова. Нужно только ваше желание и немного терпения.
            </p>
            <HomeCarousel />
            <h2>Начните обучение прямо сейчас</h2>
            <p>Быстро выучить иностранные слова с нашим сервисом может каждый!</p>
            <p><strong>Знание иностранных языков в наше время очень полезный навык</strong>:</p>
            <ul>
                <li>Он раздвигает границы общения.</li>
                <li>Делает доступным поиск информации на иностранных сайтах.</li>
                <li>Появляется возможность читать книги в первоисточнике.</li>
                <li>Вы можете наконец-то узнать о чём поют ваши любимые исполнители.</li>
            </ul>
            <p>Нет смысла откладывать, начните прямо сейчас!</p>
            <Button as={Link} to={Settings.links.lessons}>ПОКАЗАТЬ УРОКИ</Button>
        </>
    )
}

export default Home;