import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import WithLearnService from '../hoc';
import { useParams, useNavigate } from "react-router-dom";
import { Settings } from '../../settings';
import { Spinner, Row, Col } from 'react-bootstrap';
import LessonMenu from '../lesson-menu';

const Lesson = (props) => {
    const { LearnService, currentLanguage } = props;
    const { title } = props;
    const params = useParams();
    const navigate = useNavigate();

    const [words, setWords] = useState(undefined);

    useEffect(() => {
        if (currentLanguage === undefined || currentLanguage.prefix !== params.prefix) {
            navigate(Settings.links.lessons);
        }
        if (words === undefined) {
            const abortController = new AbortController();
            LearnService.getWords(params.prefix, params.id, abortController)
                .then(res => {
                    console.log('words load', res);
                    setWords(res);
                })
                .catch(err => {
                    console.log('words load error', err);
                })
            return function cancel() {
                abortController.abort();
            }
        }
    });

    const updateWords = () => {
        console.log('update words clicked...');
        setWords(undefined);
    }

    return (
        <>
            <Row>
                <Col>
                    <h1>{title}</h1>
                </Col>
                <LessonMenu update={updateWords} loading={words === undefined} />
            </Row>
            {words === undefined ?
                <Spinner animation="border" variant="primary" /> :
                React.cloneElement(props.children, { words })
            }


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        currentLanguage: state.currentLanguage,
    }
}

const mapDispatchToProps = {
};

export default WithLearnService()(connect(mapStateToProps, mapDispatchToProps)(Lesson));