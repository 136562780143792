import React from 'react';
import Workout from '../workout';
import Lesson from '../lesson';

function WorkoutPage() {
    return (
        <Lesson
            title='Повторение'
        >
            <Workout />
        </Lesson>
    )
}

export default WorkoutPage;